import { last, head, includes } from 'lodash'

export const FIELD_PATH_SEPARATOR = '.'

export const toFieldPath = (
  fieldName: string,
  referenceFieldName: string,
): string =>
  referenceFieldName
    ? [referenceFieldName, fieldName].join(FIELD_PATH_SEPARATOR)
    : fieldName

export const getFieldFromReferencedCollectionName = (
  fieldPath: string,
): string | undefined => last(fieldPath.split(FIELD_PATH_SEPARATOR))

export const getReferenceFieldName = (fieldPath: string): string | undefined =>
  head(fieldPath.split(FIELD_PATH_SEPARATOR))
