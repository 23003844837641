export default {
  ERROR: 1,
  DATASET_FAILED_TO_LOAD: 3,
  CONNECTION_CONFIG_FAILED_TO_LOAD: 4,
  FILTER_PANEL_FAILED_TO_LOAD: 5,
  SORT_PANEL_FAILED_TO_LOAD: 6,
  ADD_COLUMN_PANEL_FAILED_TO_LOAD: 7,
  CHANGE_COLLECTION_PANEL_FAILED_TO_LOAD: 8,
  CREATE_DATASET_PANEL_FAILED_TO_LOAD: 9,
  NEW_DYNAMIC_PAGE_PANEL_FAILED_TO_LOAD: 10,
  DELETE_DYNAMIC_PAGE_PANEL_FAILED_TO_LOAD: 11,
  DYNAMIC_PAGE_INFO_PANEL_FAILED_TO_LOAD: 12,
  DYNAMIC_PAGE_SEO_PANEL_FAILED_TO_LOAD: 13,
  DYNAMIC_PAGES_HOOKS_PANEL_FAILED_TO_LOAD: 14,
  ERROR_PANEL_FAILED_TO_LOAD: 15,
  ADD_PRESET_PANEL_FAILED_TO_LOAD: 16,
  ADD_FIELD_PANEL_FAILED_TO_LOAD: 17,
  NEW_DYNAMIC_PAGE_PANEL_V3_FAILED_TO_LOAD: 18,
  CREATE_COLLECTION_PANEL_FAILED_TO_LOAD: 19,
}
